import { APICore } from './apiCore';

const api = new APICore();

// account
function login(params: { email: string; password: string }) {
    console.log('this is the final login api route')
    console.log(params.email,params.password)
    const baseUrl = '/login';
    return api.create(`${baseUrl}`, params);
}

function logout() {
    const baseUrl = '/logout';
    return api.create(`${baseUrl}`, {});
}

function signup(params: { fullname: string; email: string; password: string }) {
    const baseUrl = '/register';
    console.log('this is register function call')
    console.log(params)
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
    const baseUrl = '/forget-password';
    return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword };
