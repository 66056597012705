export const LeadsActionTypes = {
    SET_SCHEDULE_LEAD_COUNT: "SET_SCHEDULE_LEAD_COUNT",
    FETCH_SCHEDULE_LEAD_COUNT: "FETCH_SCHEDULE_LEAD_COUNT",
    SET_SCHEDULE_LEAD: "SET_SCHEDULE_LEAD",
    FETCH_SCHEDULE_LEAD: "FETCH_SCHEDULE_LEAD",
    FETCH_LEAD_SUCCESS: "FETCH_LEAD_SUCCESS",
    FETCH_LEAD_FAILURE: "FETCH_LEAD_FAILURE"
  };
  
  export default LeadsActionTypes;
  